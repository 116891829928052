import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { Menu, MenuService } from './menu.service';
import { NgxRolesService, ValidationFn } from 'ngx-permissions';
import { firstValueFrom } from 'rxjs';
import { Title } from '@angular/platform-browser';

export const MENU: Menu[] = [
  {
    route: 'dashboard',
    name: 'dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  {
    route: 'rewards',
    name: 'rewards',
    type: 'link',
    icon: 'emoji_events',
  },
  {
    route: 'coupons',
    name: 'coupons',
    type: 'sub',
    icon: 'redeem',
    children: [
      {
        route: 'welcome-coupons',
        name: 'welcomeCoupons',
        icon: '',
        type: 'link',
      },
      {
        route: 'coupons',
        name: 'coupons',
        type: 'link',
        icon: '',
      },
    ],
  },
  {
    route: 'challenges',
    name: 'challenges',
    type: 'link',
    icon: 'sports_score',
  },
  {
    route: 'point-system',
    name: 'pointSystem',
    type: 'link',
    icon: 'poll',
  },
  {
    route: 'branch-office',
    name: 'branches',
    type: 'link',
    icon: 'store',
  },
  {
    route: 'marketing',
    name: 'marketing',
    type: 'sub',
    icon: 'auto_graph',
    children: [
      {
        route: 'push-notification',
        name: 'pushNotification',
        icon: '',
        type: 'link',
      },
      {
        route: 'promotions',
        name: 'promotions',
        type: 'link',
        icon: '',
      },
    ],
  },
  {
    route: 'customers',
    name: 'customers',
    type: 'link',
    icon: 'person',
  },
  {
    route: 'settings',
    name: 'settings',
    type: 'sidenavSub',
    icon: 'settings',
    children: [
      {
        name: 'account',
        type: 'link',
        icon: 'person',
        route: 'account',
        description: 'menu.settings.account.description',
      },
      {
        name: 'company',
        type: 'link',
        icon: 'business',
        route: 'company',
        description: 'menu.settings.company.description',
      },

      {
        name: 'team',
        type: 'link',
        icon: 'groups',
        route: 'team',
        description: 'menu.settings.team.description',
        permissions: {
          only: ['business-owner-role'],
        },
      },
      {
        name: 'appearance',
        type: 'link',
        icon: 'palette',
        route: 'appearance',
        description: 'menu.settings.appearance.description',
      },
    ],
  },
  {
    name: 'helpCenter',
    route: 'help-center',
    type: 'sub',
    icon: 'help_outline',
    children: [
      {
        name: 'guides',
        route: 'guides',
        icon: '',
        type: 'link',
      },
      {
        name: 'support',
        route: 'support',
        icon: '',
        type: 'link',
      },
    ],
  },
];

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private readonly _rolesKey = 'userRoles';

  constructor(
    private readonly _auth: AuthService,
    private readonly _menu: MenuService,
    private readonly _roles: NgxRolesService,
    private readonly _title: Title
  ) {}

  async load(): Promise<void> {
    try {
      const user = await firstValueFrom(this._auth.user$);
      this._setPermissions(user);
      this._setMenu(MENU);
      this._setTitle(user);
    } catch (error) {
      throw new Error('Error during startup');
    }
  }

  private _setMenu(menu: Menu[]): void {
    this._menu.addNamespace(menu, 'menu');
    this._menu.set(menu);
  }

  private _setPermissions(user: User | null | undefined): void {
    if (user?.[this._rolesKey]) {
      const roles = user[this._rolesKey] as string[];

      const rolesObj: {
        [name: string]: ValidationFn | string[];
      } = roles.reduce(
        (obj, key) => {
          obj[key] = () => true;
          return obj;
        },
        {} as {
          [name: string]: ValidationFn | string[];
        }
      );

      this._roles.addRoles(rolesObj);
    }
  }

  private _setTitle(user: User | null | undefined): void {
    if (user?.['org_display_name']) {
      this._title.setTitle(`${user['org_display_name']} - REBAC`);
    }
  }
}
